@media screen and (max-width: 1000px) {
  .side-menu {
    opacity: 0;
    transform: translateX(100%);
  }
}
@media screen and (min-width: 1001px) {
  .side-menu {
    display: flex;
    transform: translateX(0);
  }
}
.side-menu {
  width: 10ch;
  transition: all 500ms;
  position: fixed;
  right: 2rem;
  bottom: 10%;
  flex-direction: column;
}

.link {
  cursor: pointer;
}

.link.current {
  position: relative;
  color: var(--turq-blue);
  font-weight: 700;
}

.link.current::before {
  content: ">";
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translate(-100%, -50%);
}
