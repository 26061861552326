@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body,
#root {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
  background-color: var(--dark-blue);
}

#root {
  display: flex;
}

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  padding: 0;
  margin: 0;
  color: var(--light-text);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

::selection {
  color: var(--turq-blue);
  background: var(--pale-grey);
}

::mox-selection {
  color: var(--turq-blue);
  background: var(--pale-grey);
}
