html {
  --light-text: #d1e6e0;
  --grey-text: #acbdb8;
  --dark-blue: #081f2c;
  --lighter-blue: #09314b;
  --lighter-blue-hover: #0d4163;
  --turq-blue: #3cdbc0;
  --pale-grey: #586672;
  --yellow: #f1be48;
  --orange: #e03c31;
}
