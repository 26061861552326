@media screen and (min-width: 0px) {
  .skills {
    --cols: 2;
    --row-gap: 1.5rem;
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .skills {
    width: fit-content;
    --cols: 3;
    --row-gap: 1rem;
  }
}

@media screen and (min-width: 720px) {
  .skills {
    --cols: 4;
    --row-gap: 1rem;
  }
}

.about {
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.about-intro {
  max-width: 75ch;
}

.skills {
  display: grid;
  gap: var(--row-gap) 2.5rem;
  grid-template-columns: repeat(var(--cols), 1fr);
}

.skills ul {
  list-style: none;
  padding-left: 1rem;
}

.skills li {
  transition: all 150ms 50ms;
  margin-top: 0.25rem;
}

.skills .iconWrapper {
  margin-right: 0.5rem;
}

.skills-heading {
  grid-column: 1 / -1;
  color: var(--turq-blue);
}
.skills-category {
  padding: 0.5rem;
}

.skills-category-title {
  margin-bottom: 1rem;
}

.skills-category:hover li {
  transition: all 300ms 50ms;
  margin-top: 0.5rem;
}
