@media only screen and (min-width: 0px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}
