@media only screen and (min-width: 0px) {
  .intro {
    padding: 5rem 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .intro {
    padding: 5rem 6rem;
  }
}

@media only screen and (min-width: 1200px) {
  .intro {
    padding: 5rem 8rem;
  }
}

@media only screen and (min-width: 1920px) {
  .intro {
    padding: 5rem 10rem;
  }
}

@media only screen and (max-width: 768px) {
  .intro {
    align-content: center;
    align-items: center;
  }
}
.intro {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  scroll-snap-align: center;
  width: 100%;
  height: 80vh;
  margin: 2rem 0;
}

.intro-header {
  display: flex;
}

.intro-content {
  position: relative;
  width: fit-content;
}

.intro-content::after {
  content: "";
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--pale-grey);
}

.title {
  font-size: 2.7rem;
  max-width: fit-content;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  width: 0px;
  margin: 0;
  overflow: hidden;
  border-right: 2px solid var(--turq-blue);
  padding-right: 1rem;
  animation: typing 6s steps(60, end) 50ms forwards,
    cursor 1000ms step-end infinite;
}

.title strong {
  font-weight: inherit;
  color: var(--turq-blue);
}

.title-gt {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0 15px 0 0;
}

.hidden {
  transition: all 1s;
  transition-delay: 50ms;
  transform: translateY(50%);
  filter: blur(3px);
  opacity: 0;
}

.show {
  filter: blur(0);
  opacity: 1;
  transform: translateY(0);
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes cursor {
  0%,
  100% {
    border-right-color: transparent;
  }

  50% {
    border-right-color: var(--turq-blue);
  }
}

.contacts {
  margin-top: 4rem;
  display: flex;
  gap: 1rem;
}

.contacts .icon {
  font-size: 2.25rem;
  transition: all 100ms 10ms;
}

.contacts .icon:hover {
  color: var(--turq-blue);
}
