.emails {
  width: fit-content;
  display: grid;
  grid-template-areas: "a b" "c d";
  gap: 1.5rem;
}

.label .tooltip {
  pointer-events: none;
  opacity: 0;
  transition: all 50ms 0ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  right: 0;
  transform: translateX(-50%);
  font-size: 0.9rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  color: var(--light-text);
  background-color: var(--lighter-blue-hover);
}

.label {
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-weight: 500;
  width: fit-content;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.25rem 1rem;
  width: 100%;
  cursor: pointer;
  transition: background 0ms;
}
.label:hover .tooltip {
  opacity: 1;
  transition: all 200ms 50ms;
  transform: translateX(0);
}
.academic.label {
  grid-area: c;
  background-color: #a29bfe;
}

.personal.label {
  grid-area: a;
  background-color: #81ecec;
  color: #2d3436;
}
.label:hover {
  transition: background 50ms 200ms;
  background-color: #2d3436;
}

.email {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.personal.email {
  grid-area: b;
}

.academic.email {
  grid-area: d;
}
